@import './pills.scss';
@import './mixins';
@import './variables';

// Titles

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;

  &:first-child {
    margin-top: 0;
  }
}

.h1 {
  font-size: 2.5rem;
}

.h2 {
  font-size: 2rem;
}

.h3 {
  font-size: 1.75rem;
}

.h4 {
  font-size: 1.5rem;
}

.h5 {
  font-size: 1.25rem;
}

.h6 {
  font-size: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.width-100 {
  width: 100%;

  .p-dropdown {
    width: 100%;
  }
}

.text-no-select {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.p-password-meter {
  background: #dee2e6;

  .p-password-strength.weak {
    background: #d32f2f;
  }

  .p-password-strength.medium {
    background: #fbc02d;
  }

  .p-password-strength.strong {
    background: #689f38;
  }
}

.p-inputtext {
  &::-webkit-input-placeholder {
    color: var(--text-color-secondary);
  }
  &:-moz-placeholder {
    color: var(--text-color-secondary);
  }
  &::-moz-placeholder {
    color: var(--text-color-secondary);
  }
  &:-ms-input-placeholder {
    color: var(--text-color-secondary);
  }
}

.p-dropdown {
  .p-dropdown-label {
    &.p-placeholder {
      color: var(--text-color-secondary);
    }
  }
}

.p-button-tertiary {
  color: var(--text-color);
  background-color: var(--surface-f);
  border-color: var(--surface-f);

  &:hover {
    color: var(--surface-a) !important;
    background-color: var(--text-color) !important;
    border-color: var(--text-color) !important;
  }
}

.p-button {
  &.p-button-xsm {
    font-size: 0.5rem;
    padding: 0.2rem 0.5rem;

    .pi {
      font-size: 0.8rem;
      padding: 0.2rem 0.5rem;
    }
    &.p-button-icon-only {
      width: 2rem;
      &.p-button-rounded {
        height: 2rem;
      }
    }
  }
}

.custom-dropdown-button {
  .p-dropdown {
    border: none;

    .p-dropdown-trigger {
      color: var(--surface-a);
      background: var(--primary-color);
      border-color: var(--primary-color);
    }

    .p-inputtext {
      border: 1px solid var(--surface-d) !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: 0 !important;
    }
  }
  .p-button {
    width: 190px;
    background-color: transparent !important;
    border: none !important;
    padding: 0;
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;

    .p-button-label {
      display: flex;
      justify-content: flex-start;
      padding: 0.5rem;
      color: var(--text-color);
      border: 1px solid var(--surface-d);
      border-radius: 6px;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      background-color: #f5f9ff;
    }

    .p-button-icon {
      width: 2.357rem;
      background-color: var(--primary-color);
      height: 100%;
      margin-left: 0;
      padding: 9px 0;
      border: 1px solid var(--primary-color);
      border-top: 0;
      font-size: 1rem;
    }
  }

  &.custom-dropdown-button-light {
    .p-dropdown {
      .p-dropdown-label {
        background-color: var(--surface-a) !important;
      }
    }
  }
}

.layout-menu-dark {
  .custom-dropdown-button {
    .p-button {
      .p-button-label {
        background-color: #545b67;
      }
    }
  }
}

.p-panelmenu {
  .p-submenu-list {
    margin-left: 15px !important;
  }
}

.area-width {
  @include modifier('half') {
    width: 50%;
  }
  @include modifier('full') {
    width: 100%;
  }
}

p-inputnumber {
  &.min-max {
    .p-inputnumber-input {
      text-align: center;
      color: var(--primary-color);
      font-weight: bold;
    }

    .p-inputnumber-button-up {
      padding: 0 1.5rem;
      ::before {
        content: 'MAX';
        font-family: var(--font-family);
      }
    }

    .p-inputnumber-button-down {
      padding: 0 1.5rem;
      ::before {
        content: 'MIN';
        font-family: var(--font-family);
      }
    }
  }
}

p-badge {
  &[size*='small'] {
    .p-badge {
      font-size: 0.6rem;
      font-weight: 500;
      min-width: 15px;
      height: 14px;
      line-height: 1.1rem;
      border-radius: 10px;
    }
  }
  &[size*='x-small'] {
    .p-badge {
      font-weight: 500;
      min-width: 8px;
      padding: 0px 0.3rem;
      height: 8px;
      border-radius: 10px;
    }
  }
  .p-badge-danger {
    background-color: #fb8874 !important;
    color: white !important;
  }
  .p-badge-warning {
    color: white !important;
    background-color: #e7b654 !important;
  }
}

.hover {
  @include modifier('pointer') {
    cursor: pointer;
  }
}

.card {
  height: max-content;

  @include modifier('noBackground') {
    background-color: transparent;
    border: 1px solid $gray-light;
    border-radius: $border-radius-normal;
  }

  &.hoverBorder {
    border: 1px solid transparent;

    &:hover {
      cursor: pointer;

      border-color: #bbe7f4;
    }

    &.selected {
      border-color: #0089b3;
    }
  }
}

.slightGrayout {
  filter: opacity(0.77) contrast(0.93);
}

.fieldType-chip {
  font-size: $font-size-2;
  padding: $padding-xxxs $padding-xs;
  border-radius: $border-radius-normal;
  width: max-content;
  align-self: center;

  &.freeText {
    background-color: $brown-light;
    color: $brown-dark;
  }

  &.radioGroup {
    background-color: $red-light;
    color: $red-dark;
  }

  &.disclosure {
    background-color: $dark-red-light;
    color: $dark-red-dark;
  }

  &.disclaimer {
    background-color: $teal-light;
    color: $teal-dark;
  }

  &.dropdown {
    background-color: $green-light;
    color: $green-dark;
  }

  &.checkbox {
    background-color: $blue-light;
    color: $blue-dark;
  }

  &.checkboxGroup {
    background-color: $blue-light;
    color: $blue-dark;
  }

  &.signature {
    background-color: $purple-light;
    color: $purple-dark;
  }

  &.date {
    background-color: $lime-light;
    color: $lime-dark;
  }

  &.staticText {
    border: 1px solid $gray-light;
    color: $gray-dark;
  }

  &.number {
    background-color: $navy-light;
    color: $navy-dark;
  }

  &.table {
    background-color: $gray-light;
    color: black;
  }

  &.image {
    background-color: $teal-light;
    color: $teal-dark;
  }

  &.pdf {
    background-color: $pink-light;
    color: $pink-dark;
  }
}

.numberOfPages-chip {
  font-size: $font-size-2;
  padding: $padding-xxxs $padding-xs;
  border-radius: $border-radius-normal;
  width: max-content;
  align-self: center;
  background-color: $blue-light;
  color: $blue-dark;
}

.languageTag-chip-en {
  font-size: $font-size-2;
  padding: $padding-xxxs $padding-xs;
  border-radius: $border-radius-normal;
  width: max-content;
  align-self: center;
  background-color: $navy-light;
  color: $navy-dark;
}

.languageTag-chip-fr {
  font-size: $font-size-2;
  padding: $padding-xxxs $padding-xs;
  border-radius: $border-radius-normal;
  width: max-content;
  align-self: center;
  background-color: $orange-light;
  color: $orange-dark;
}

.singleLineElipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiLineElipsis {
  overflow: hidden;
  text-overflow: ellipsis;

  @supports (display: -webkit-box) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

a {
  &:hover {
    cursor: pointer;
  }
}

// Custom shadows

.p-custom-shadow-3 {
  box-shadow: var(--custom-shadow-3);
}

// cdk

.cdk-drop-list {
  &.sortable-ghost {
    opacity: 0.2;
  }
}

.drag-icon {
  width: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: move;
}

.cdk-drag-preview {
  z-index: 1200 !important;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0.3;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

// Field properties

.details {
  @include element('field') {
    @include element('content') {
      @include element('property') {
        min-height: 33px;

        @include element('label') {
          min-width: 120px;
        }

        @include element('subProperty') {
          @include element('label') {
            min-width: 160px;
          }
        }

        @include element('content') {
          &:not(.fieldType-chip) {
            width: 100%;
          }
        }
      }
    }
  }

  @include element('emptyState') {
    position: relative;

    img {
      opacity: 0.06;
      height: 125px;
      filter: grayscale(20%);
      transform: rotate(4deg);
    }

    > :not(img) {
      position: absolute;
      left: 0;
    }
  }
}

.layout-menu-light {
  .highlight {
    background-color: #ffff00;
  }
}
.layout-menu-dark {
  .highlight {
    color: #000000;
    background-color: #ffff00;
  }
}

// Dark mode styles

.layout-topbar-dark {
  ul {
    &.p-tabmenu-nav {
      background: #293241;
    }
  }

  // Scrollbar
  ::-webkit-scrollbar-track {
    background-color: var(--surface-b);
  }

  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--surface-d);
    border-radius: 10px;
    -webkit-box-shadow: inset 0px 0px 0px 4px var(--surface-b);
  }

  scrollbar-color: var(--surface-d) var(--surface-b);
  scrollbar-width: thin;
}

// Custom table styles

p-table {
  td {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.p-tabview {
  .p-tabview-nav {
    li {
      .p-tabview-nav-link {
        border-radius: 0;
      }
    }
  }
}

.isReadonlyInput {
  opacity: 0.7;
  outline: none;
  pointer-events: none;
}

// Custom group option styles

.default-group-option {
  position: relative;
  &:before {
    content: '';
    height: 100%;
    width: 5px;
    border-radius: 5px 0 0 5px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--primary-color); /* Old browsers */
  }
}

.required-group-option {
  position: relative;
  &:before {
    content: '';
    height: 100%;
    width: 5px;
    border-radius: 5px 0 0 5px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: $dark-red-light; /* Old browsers */
  }
}

.required-and-default-group-option {
  position: relative;
  &:before {
    content: '';
    height: 100%;
    width: 5px;
    border-radius: 5px 0 0 5px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: brown; /* Old browsers */
    background: -moz-linear-gradient(
      top,
      var(--primary-color) 0%,
      var(--primary-color) 50%,
      var(--primary-color) 50%,
      $dark-red-light 50%,
      $dark-red-light 50%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      var(--primary-color) 0%,
      var(--primary-color) 50%,
      var(--primary-color) 50%,
      $dark-red-light 50%,
      $dark-red-light 50%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      var(--primary-color) 0%,
      var(--primary-color) 50%,
      var(--primary-color) 50%,
      $dark-red-light 50%,
      $dark-red-light 50%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--primary-color)', endColorstr='$dark-red-light',GradientType=0 ); /* IE6-9 */
  }
}

p-calendar {
  min-width: 9em;

  .p-calendar {
    .p-datepicker {
      min-width: 356px;
    }
  }
}
