.status-chip {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;
  max-width: max-content;

	&.chip-green {
		background: #C8E6C9;
		color: #256029;
	}

	&.chip-red{
		background: #FFCDD2;
		color: #C63737;
	}

	&.chip-yellow {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.chip-blue {
		background: #B3E5FC;
		color: #23547B;
	}

	&.chip-purple{
		background: #ECCFFF;
		color: #694382;
	}

	&.chip-orange {
		background: #FFD8B2;
		color: #805B36;
	}
}
