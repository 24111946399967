.status-chip {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
  max-width: max-content;
}
.status-chip.chip-green {
  background: #C8E6C9;
  color: #256029;
}
.status-chip.chip-red {
  background: #FFCDD2;
  color: #C63737;
}
.status-chip.chip-yellow {
  background: #FEEDAF;
  color: #8A5340;
}
.status-chip.chip-blue {
  background: #B3E5FC;
  color: #23547B;
}
.status-chip.chip-purple {
  background: #ECCFFF;
  color: #694382;
}
.status-chip.chip-orange {
  background: #FFD8B2;
  color: #805B36;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}
.h1:first-child,
.h2:first-child,
.h3:first-child,
.h4:first-child,
.h5:first-child,
.h6:first-child {
  margin-top: 0;
}

.h1 {
  font-size: 2.5rem;
}

.h2 {
  font-size: 2rem;
}

.h3 {
  font-size: 1.75rem;
}

.h4 {
  font-size: 1.5rem;
}

.h5 {
  font-size: 1.25rem;
}

.h6 {
  font-size: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.width-100 {
  width: 100%;
}
.width-100 .p-dropdown {
  width: 100%;
}

.text-no-select {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
}

.p-password-meter {
  background: #dee2e6;
}
.p-password-meter .p-password-strength.weak {
  background: #d32f2f;
}
.p-password-meter .p-password-strength.medium {
  background: #fbc02d;
}
.p-password-meter .p-password-strength.strong {
  background: #689f38;
}

.p-inputtext::-webkit-input-placeholder {
  color: var(--text-color-secondary);
}
.p-inputtext:-moz-placeholder {
  color: var(--text-color-secondary);
}
.p-inputtext::-moz-placeholder {
  color: var(--text-color-secondary);
}
.p-inputtext:-ms-input-placeholder {
  color: var(--text-color-secondary);
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: var(--text-color-secondary);
}

.p-button-tertiary {
  color: var(--text-color);
  background-color: var(--surface-f);
  border-color: var(--surface-f);
}
.p-button-tertiary:hover {
  color: var(--surface-a) !important;
  background-color: var(--text-color) !important;
  border-color: var(--text-color) !important;
}

.p-button.p-button-xsm {
  font-size: 0.5rem;
  padding: 0.2rem 0.5rem;
}
.p-button.p-button-xsm .pi {
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
}
.p-button.p-button-xsm.p-button-icon-only {
  width: 2rem;
}
.p-button.p-button-xsm.p-button-icon-only.p-button-rounded {
  height: 2rem;
}

.custom-dropdown-button .p-dropdown {
  border: none;
}
.custom-dropdown-button .p-dropdown .p-dropdown-trigger {
  color: var(--surface-a);
  background: var(--primary-color);
  border-color: var(--primary-color);
}
.custom-dropdown-button .p-dropdown .p-inputtext {
  border: 1px solid var(--surface-d) !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0 !important;
}
.custom-dropdown-button .p-button {
  width: 190px;
  background-color: transparent !important;
  border: none !important;
  padding: 0;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.custom-dropdown-button .p-button .p-button-label {
  display: flex;
  justify-content: flex-start;
  padding: 0.5rem;
  color: var(--text-color);
  border: 1px solid var(--surface-d);
  border-radius: 6px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f5f9ff;
}
.custom-dropdown-button .p-button .p-button-icon {
  width: 2.357rem;
  background-color: var(--primary-color);
  height: 100%;
  margin-left: 0;
  padding: 9px 0;
  border: 1px solid var(--primary-color);
  border-top: 0;
  font-size: 1rem;
}
.custom-dropdown-button.custom-dropdown-button-light .p-dropdown .p-dropdown-label {
  background-color: var(--surface-a) !important;
}

.layout-menu-dark .custom-dropdown-button .p-button .p-button-label {
  background-color: #545b67;
}

.p-panelmenu .p-submenu-list {
  margin-left: 15px !important;
}

.area-width--half {
  width: 50%;
}
.area-width--full {
  width: 100%;
}

p-inputnumber.min-max .p-inputnumber-input {
  text-align: center;
  color: var(--primary-color);
  font-weight: bold;
}
p-inputnumber.min-max .p-inputnumber-button-up {
  padding: 0 1.5rem;
}
p-inputnumber.min-max .p-inputnumber-button-up ::before {
  content: "MAX";
  font-family: var(--font-family);
}
p-inputnumber.min-max .p-inputnumber-button-down {
  padding: 0 1.5rem;
}
p-inputnumber.min-max .p-inputnumber-button-down ::before {
  content: "MIN";
  font-family: var(--font-family);
}

p-badge[size*=small] .p-badge {
  font-size: 0.6rem;
  font-weight: 500;
  min-width: 15px;
  height: 14px;
  line-height: 1.1rem;
  border-radius: 10px;
}
p-badge[size*=x-small] .p-badge {
  font-weight: 500;
  min-width: 8px;
  padding: 0px 0.3rem;
  height: 8px;
  border-radius: 10px;
}
p-badge .p-badge-danger {
  background-color: #fb8874 !important;
  color: white !important;
}
p-badge .p-badge-warning {
  color: white !important;
  background-color: #e7b654 !important;
}

.hover--pointer {
  cursor: pointer;
}

.card {
  height: max-content;
}
.card--noBackground {
  background-color: transparent;
  border: 1px solid var(--gray-light, #e4e4e4);
  border-radius: var(--border-radius-normal, 6px);
}
.card.hoverBorder {
  border: 1px solid transparent;
}
.card.hoverBorder:hover {
  cursor: pointer;
  border-color: #bbe7f4;
}
.card.hoverBorder.selected {
  border-color: #0089b3;
}

.slightGrayout {
  filter: opacity(0.77) contrast(0.93);
}

.fieldType-chip {
  font-size: var(--font-size-2, 0.85rem);
  padding: var(--padding-xxxs, 3px) var(--padding-xs, 10px);
  border-radius: var(--border-radius-normal, 6px);
  width: max-content;
  align-self: center;
}
.fieldType-chip.freeText {
  background-color: var(--brown-light, #f8e5b1);
  color: var(--brown-dark, #b76b02);
}
.fieldType-chip.radioGroup {
  background-color: var(--red-light, #fed7d7);
  color: var(--red-dark, #f04242);
}
.fieldType-chip.disclosure {
  background-color: var(--dark-red-light, #f8d1c6);
  color: var(--dark-red-dark, #812c0d);
}
.fieldType-chip.disclaimer {
  background-color: var(--teal-light, #9ae0d9);
  color: var(--teal-dark, #0b655b);
}
.fieldType-chip.dropdown {
  background-color: var(--green-light, #d1fbd0);
  color: var(--green-dark, #009c47);
}
.fieldType-chip.checkbox {
  background-color: var(--blue-light, #bbe7f4);
  color: var(--blue-dark, #0089b3);
}
.fieldType-chip.checkboxGroup {
  background-color: var(--blue-light, #bbe7f4);
  color: var(--blue-dark, #0089b3);
}
.fieldType-chip.signature {
  background-color: var(--purple-light, #f9ddf9);
  color: var(--purple-dark, #800080);
}
.fieldType-chip.date {
  background-color: var(--lime-light, #dbfda1);
  color: var(--lime-dark, #648c19);
}
.fieldType-chip.staticText {
  border: 1px solid var(--gray-light, #e4e4e4);
  color: var(--gray-dark, #525252);
}
.fieldType-chip.number {
  background-color: var(--navy-light, #d9dfff);
  color: var(--navy-dark, #4053b5);
}
.fieldType-chip.table {
  background-color: var(--gray-light, #e4e4e4);
  color: black;
}
.fieldType-chip.image {
  background-color: var(--teal-light, #9ae0d9);
  color: var(--teal-dark, #0b655b);
}
.fieldType-chip.pdf {
  background-color: var(--pink-light, #e7acda);
  color: var(--pink-dark, #cc42b1);
}

.numberOfPages-chip {
  font-size: var(--font-size-2, 0.85rem);
  padding: var(--padding-xxxs, 3px) var(--padding-xs, 10px);
  border-radius: var(--border-radius-normal, 6px);
  width: max-content;
  align-self: center;
  background-color: var(--blue-light, #bbe7f4);
  color: var(--blue-dark, #0089b3);
}

.languageTag-chip-en {
  font-size: var(--font-size-2, 0.85rem);
  padding: var(--padding-xxxs, 3px) var(--padding-xs, 10px);
  border-radius: var(--border-radius-normal, 6px);
  width: max-content;
  align-self: center;
  background-color: var(--navy-light, #d9dfff);
  color: var(--navy-dark, #4053b5);
}

.languageTag-chip-fr {
  font-size: var(--font-size-2, 0.85rem);
  padding: var(--padding-xxxs, 3px) var(--padding-xs, 10px);
  border-radius: var(--border-radius-normal, 6px);
  width: max-content;
  align-self: center;
  background-color: var(--orange-light, #f8e5b1);
  color: var(--orange-dark, #d47d02);
}

.singleLineElipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiLineElipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
@supports (display: -webkit-box) {
  .multiLineElipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

a:hover {
  cursor: pointer;
}

.p-custom-shadow-3 {
  box-shadow: var(--custom-shadow-3);
}

.cdk-drop-list.sortable-ghost {
  opacity: 0.2;
}

.drag-icon {
  width: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: move;
}

.cdk-drag-preview {
  z-index: 1200 !important;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0.3;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.details-field-content-property {
  min-height: 33px;
}
.details-field-content-property-label {
  min-width: 120px;
}
.details-field-content-property-subProperty-label {
  min-width: 160px;
}
.details-field-content-property-content:not(.fieldType-chip) {
  width: 100%;
}
.details-emptyState {
  position: relative;
}
.details-emptyState img {
  opacity: 0.06;
  height: 125px;
  filter: grayscale(20%);
  transform: rotate(4deg);
}
.details-emptyState > :not(img) {
  position: absolute;
  left: 0;
}

.layout-menu-light .highlight {
  background-color: #ffff00;
}

.layout-menu-dark .highlight {
  color: #000000;
  background-color: #ffff00;
}

.layout-topbar-dark {
  scrollbar-color: var(--surface-d) var(--surface-b);
  scrollbar-width: thin;
}
.layout-topbar-dark ul.p-tabmenu-nav {
  background: #293241;
}
.layout-topbar-dark ::-webkit-scrollbar-track {
  background-color: var(--surface-b);
}
.layout-topbar-dark ::-webkit-scrollbar {
  width: 15px;
}
.layout-topbar-dark ::-webkit-scrollbar-thumb {
  background-color: var(--surface-d);
  border-radius: 10px;
  -webkit-box-shadow: inset 0px 0px 0px 4px var(--surface-b);
}

p-table td div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border-radius: 0;
}

.isReadonlyInput {
  opacity: 0.7;
  outline: none;
  pointer-events: none;
}

.default-group-option {
  position: relative;
}
.default-group-option:before {
  content: "";
  height: 100%;
  width: 5px;
  border-radius: 5px 0 0 5px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary-color);
  /* Old browsers */
}

.required-group-option {
  position: relative;
}
.required-group-option:before {
  content: "";
  height: 100%;
  width: 5px;
  border-radius: 5px 0 0 5px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--dark-red-light, #f8d1c6);
  /* Old browsers */
}

.required-and-default-group-option {
  position: relative;
}
.required-and-default-group-option:before {
  content: "";
  height: 100%;
  width: 5px;
  border-radius: 5px 0 0 5px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: brown;
  /* Old browsers */
  background: -moz-linear-gradient(top, var(--primary-color) 0%, var(--primary-color) 50%, var(--primary-color) 50%, var(--dark-red-light, #f8d1c6) 50%, var(--dark-red-light, #f8d1c6) 50%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, var(--primary-color) 0%, var(--primary-color) 50%, var(--primary-color) 50%, var(--dark-red-light, #f8d1c6) 50%, var(--dark-red-light, #f8d1c6) 50%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, var(--primary-color) 0%, var(--primary-color) 50%, var(--primary-color) 50%, var(--dark-red-light, #f8d1c6) 50%, var(--dark-red-light, #f8d1c6) 50%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="var(--primary-color)", endColorstr="$dark-red-light",GradientType=0 );
  /* IE6-9 */
}

p-calendar {
  min-width: 9em;
}
p-calendar .p-calendar .p-datepicker {
  min-width: 356px;
}