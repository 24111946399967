@mixin modifier($element) {
  &--#{$element} {
    @content;
  }
}

@mixin element($element) {
  &-#{$element} {
    @content;
  }
}
