// Fonts

$font-size-1: var(--font-size-1, 0.7rem) !default;
$font-size-2: var(--font-size-2, 0.85rem) !default;
$font-size-3: var(--font-size-3, 1rem) !default;
$font-size-4: var(--font-size-4, 1.15rem) !default;
$font-size-5: var(--font-size-5, 1.3rem) !default;
$font-size-6: var(--font-size-6, 1.5rem) !default;
$font-size-7: var(--font-size-7, 2rem) !default;
$font-size-8: var(--font-size-8, 3rem) !default;
$font-size-9: var(--font-size-9, 4.5rem) !default;

// Border-radius

$border-radius-none: var(--border-radius-none, 0) !default;
$border-radius-small: var(--border-radius-small, 2px) !default;
$border-radius-normal: var(--border-radius-normal, 6px) !default;
$border-radius-medium: var(--border-radius-medium, 8px) !default;
$border-radius-large: var(--border-radius-medium, 12px) !default;
$border-radius-rounded: var(--border-radius-rounded, 100px) !default;

// Spacings
// Paddings

$padding-xxxs: var(--padding-xxxs, 3px) !default;
$padding-xxs: var(--padding-xxs, 5px) !default;
$padding-xs: var(--padding-xs, 10px) !default;
$padding-s: var(--padding-s, 15px) !default;
$padding-m: var(--padding-m, 20px) !default;
$padding-xm: var(--padding-xm, 25px) !default;
$padding-l: var(--padding-l, 40px) !default;
$padding-xl: var(--padding-x, 60px) !default;
$padding-xxl: var(--padding-xxl, 80px) !default;

// Margins

$margin-xxxs: var(--margin-xxxs, 3px) !default;
$margin-xxs: var(--margin-xxs, 5px) !default;
$margin-xs: var(--margin-xs, 10px) !default;
$margin-s: var(--margin-s, 15px) !default;
$margin-m: var(--margin-m, 20px) !default;
$margin-xm: var(--margin-xm, 25px) !default;
$margin-l: var(--margin-l, 40px) !default;
$margin-xl: var(--margin-xl, 60px) !default;
$margin-xxl: var(--margin-xxl, 80px) !default;

// Colors

$brown-dark: var(--brown-dark, #b76b02) !default;
$brown-light: var(--brown-light, #f8e5b1) !default;
$red-dark: var(--red-dark, #f04242) !default;
$red-light: var(--red-light, #fed7d7) !default;
$green-dark: var(--green-dark, #009c47) !default;
$green-light: var(--green-light, #d1fbd0) !default;
$orange-dark: var(--orange-dark, #d47d02) !default;
$orange-light: var(--orange-light, #f8e5b1) !default;
$pink-dark: var(--pink-dark, #cc42b1) !default;
$pink-light: var(--pink-light, #e7acda) !default;
$blue-dark: var(--blue-dark, #0089b3) !default;
$blue-light: var(--blue-light, #bbe7f4) !default;
$navy-dark: var(--navy-dark, #4053b5) !default;
$navy-light: var(--navy-light, #d9dfff) !default;
$purple-dark: var(--purple-dark, #800080) !default;
$purple-light: var(--purple-light, #f9ddf9) !default;
$gray-dark: var(--gray-dark, #525252) !default;
$gray-light: var(--gray-light, #e4e4e4) !default;
$lime-dark: var(--lime-dark, #648c19) !default;
$lime-light: var(--lime-light, #dbfda1) !default;
$dark-red-light: var(--dark-red-light, #f8d1c6) !default;
$dark-red-dark: var(--dark-red-dark, #812c0d) !default;
$teal-light: var(--teal-light, #9ae0d9) !default;
$teal-dark: var(--teal-dark, #0b655b) !default;
